<template>
  <div style="position: absolute;top: 0;width: 100%;height: 100%;background: #F0F2F5;">
    <pcHeaders></pcHeaders>

    <div class="cen">
      <div class="w">
        <div class="navTitle">
          <span @click="goNav(1)">首页</span> > <span @click="goNav(2)">{{ text1 }}</span> <span v-if="text2 !==''">></span> <span v-if="text2 !==''">{{ text2 }}</span>
        </div>

        <div class="banner">
          <div class="leftBox">
            <div class="leftTitle">
              <div style="display: flex;">
                <div style="display: flex;">
                  <div>
                    <img src="../../assets/images/bang.png"
                         alt="">
                  </div>
                  <div style="margin-left: 10px;">
                    帮助中心
                  </div>
                </div>

              </div>

            </div>
            <div style="display: flex;justify-content: center;">

              <div style="padding-top: 30px;">
                <div :class="tabIndex == 1 ? 'tabBox active' :'tabBox' "
                     @click="navTab(1,'151102','网站功能')">

                  <div>
                    <img v-if="tabIndex == 1"
                         src="../../assets/images/wite.png"
                         alt="">
                    <img v-else
                         src="../../assets/images/block.png"
                         alt="">
                  </div>
                  <div style="margin-left: 5px;">网站功能</div>
                </div>
                <div :class="tabIndex == 2 ? 'tabBox active' :'tabBox' "
                     @click="navTab(2,'151103','常见问题')">
                  <div>
                    <img v-if="tabIndex == 2"
                         src="../../assets/images/wite.png"
                         alt="">
                    <img v-else
                         src="../../assets/images/block.png"
                         alt="">
                  </div>
                  <div style="margin-left: 5px;">常见问题</div>
                </div>
                <div :class="tabIndex == 3 ? 'tabBox active' :'tabBox' "
                     @click="navTab(3,'151104','业务流程')">
                  <div>
                    <img v-if="tabIndex == 3"
                         src="../../assets/images/wite.png"
                         alt="">
                    <img v-else
                         src="../../assets/images/block.png"
                         alt="">
                  </div>
                  <div style="margin-left: 5px;">业务流程</div>
                </div>
                <div :class="tabIndex == 4 ? 'tabBox active' :'tabBox' "
                     @click="navTab(4,'151105','操作手册')">
                  <div>
                    <img v-if="tabIndex == 4"
                         src="../../assets/images/wite.png"
                         alt="">
                    <img v-else
                         src="../../assets/images/block.png"
                         alt="">
                  </div>
                  <div style="margin-left: 5px;">操作手册</div>
                </div>

                <div :class="tabIndex == 5 ? 'tabBox active' :'tabBox' "
                     @click="navTab(5,'151106','注册协议')">
                  <div>
                    <img v-if="tabIndex == 5"
                         src="../../assets/images/wite.png"
                         alt="">
                    <img v-else
                         src="../../assets/images/block.png"
                         alt="">
                  </div>
                  <div style="margin-left: 5px;">注册协议</div>
                </div>


                <!-- <div :class="tabIndex == 6 ? 'tabBox active' :'tabBox' "
                     @click="navTab(4,'1505','职称评审')">
                  <div>
                    <img v-if="tabIndex == 6"
                         src="../../assets/images/wite.png"
                         alt="">
                    <img v-else
                         src="../../assets/images/block.png"
                         alt="">
                  </div>
                  <div style="margin-left: 5px;">会员升级</div>
                </div>

                <div :class="tabIndex ==7 ? 'tabBox active' :'tabBox' "
                     @click="navTab(4,'1505','职称评审')">
                  <div>
                    <img v-if="tabIndex == 7"
                         src="../../assets/images/wite.png"
                         alt="">
                    <img v-else
                         src="../../assets/images/block.png"
                         alt="">
                  </div>
                  <div style="margin-left: 5px;">积分规则</div>
                </div>

                <div :class="tabIndex == 8 ? 'tabBox active' :'tabBox' "
                     @click="navTab(4,'1505','职称评审')">
                  <div>
                    <img v-if="tabIndex == 8"
                         src="../../assets/images/wite.png"
                         alt="">
                    <img v-else
                         src="../../assets/images/block.png"
                         alt="">
                  </div>
                  <div style="margin-left: 5px;">投诉建议</div>
                </div> -->
              </div>
            </div>

          </div>

          <div class="rightBox" v-if="text2 == ''">
            <div v-if="zixunList.length == 0" style="height: 550px;text-align: center;line-height: 550px;font-size: 20px;">
              暂无数据~
            </div>
            <div class="newBox" v-for="item in zixunList" :key="item.ID" @click="infoDetail(item.ID)">
              <div :title="item.TITLE" style="width: 700px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ item.TITLE }}</div>
              <div>{{ item.CREATETIME }}</div>
            </div>

            <pagination v-show="total>0"
                        :total="total"
                        :page.sync="queryParams.pageNum"
                        :limit.sync="queryParams.pageSize"
                        @pagination="toListMessage" />
          </div>

          <div class="rightBox" v-else v-html="content">
            
          </div>

        </div>
      </div>
    </div>

    <pcBottom></pcBottom>
    <flotRight/>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import flotRight from "@/views/component/flotRight.vue";
import { toListMessage, getInfoDetail } from '@/api/tzgg.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom,
    flotRight
  },
  data() {
    // 这里存放数据
    return {
      tabIndex: 1,
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      code: '151102',
      zixunList: [],
      text1:'网站功能',
      text2:'',
      content:''
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //面包屑导航
    goNav(id) {
      if (id == 1) {
        this.$router.push('/')
        this.text2 = ''
      }else {
        this.navTab(this.tabIndex,this.code,this.text1)
        this.text2 = ''
      }
    },
    navTab(id, code,text) {
      this.tabIndex = id;
      this.code = code;
      this.toListMessage();
      this.text1 = text
      this.text2 = ''
    },
    toListMessage() {
      this.queryParams.codeNo = this.code;
      toListMessage(this.queryParams).then(res => {
        this.zixunList = res.data.rows;
        this.total = res.data.total;
      });
    },
    infoDetail(id) {
      getInfoDetail({infoId:id}).then(res => {
        console.log(res.data.data);
        this.content = res.data.data.infoContent
        this.text2 = '资讯详情'
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.toListMessage()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.cen {
  font-family: Regular;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  background: #F0F2F5;
  .w {
    width: 1200px;
    .navTitle {
      height: 58px;
      font-size: 14px;
      color: #999999;
      line-height: 58px;
      span {
        cursor: pointer;
      }
    }
    .banner {
      display: flex;
      .leftBox {
        width: 240px;
        height: 388px;
        background: #ffffff;
        opacity: 1;
        border-radius: 0px;
        .leftTitle {
          width: 240px;
          height: 55px;
          background: #3e7eff;
          opacity: 1;
          border-radius: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 18px;
        }

        .tabBox {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #666666;
          cursor: pointer;
          width: 212px;
          height: 52px;
          margin-bottom: 4px;
          border-radius: 6px;
        }
        .active {
          background: #3481f0 !important;
          color: #fff !important;
        }
        .tabBox:hover {
          color: #436cf3;
        }
      }

      .rightBox {
        width: 940px;
        min-height: 699px;
        background: #ffffff;
        opacity: 1;
        margin-left: 20px;
        padding: 20px 30px;
        .newBox {
          height: 70px;
          border-bottom: 1px solid #f0f2f5;
          color: #666666;
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          line-height: 70px;
          cursor: pointer;
        }
        .newBox:hover {
          color: #436cf3 !important;
        }
      }
    }
  }
}
</style>